import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { StaticImage } from "gatsby-plugin-image";

const ReviewCard = ({ review }) => {
	return (
		<Card className="border-0 review-card  my-4 mx-0">
			<Card.Header className="bg-white border-0">
				<Row className="align-items-center">
					<Col xs={9}>
						<Card.Title className="montserrat-semi-bold mb-0 mb-md-1 mb-lg-0">
							{review.author}
						</Card.Title>
						<p className="pb-0 mb-0" style={{ fontSize: "1.2rem" }}>
							{review.datePublished}
						</p>
					</Col>
					<Col xs={3} className="pt-2">
						<StaticImage
							quality="100"
							src="../images/icons/google.svg"
							alt="google"
							placeholder="blurred"
							width={60}
							formats={["auto", "webp"]}
						/>
					</Col>
					<Row className="pl-4 pt-1  mt-0 ">
						<Col className="px-1 mx-0 text-center">
							{" "}
							<StaticImage
								quality="100"
								src="../images/icons/star.svg"
								alt="star"
								placeholder="blurred"
								width={15}
								formats={["auto", "webp"]}
							/>
						</Col>
						<Col className="px-1 mx-0 text-center">
							{" "}
							<StaticImage
								quality="100"
								src="../images/icons/star.svg"
								alt="star"
								placeholder="blurred"
								width={15}
								formats={["auto", "webp"]}
							/>
						</Col>
						<Col className="px-1 mx-0 text-center">
							{" "}
							<StaticImage
								quality="100"
								src="../images/icons/star.svg"
								alt="star"
								placeholder="blurred"
								width={15}
								formats={["auto", "webp"]}
							/>
						</Col>
						<Col className="px-1 mx-0 text-center">
							{" "}
							<StaticImage
								quality="100"
								src="../images/icons/star.svg"
								alt="star"
								placeholder="blurred"
								width={15}
								formats={["auto", "webp"]}
							/>
						</Col>
						<Col className="px-1 mx-0 text-center">
							{" "}
							<StaticImage
								quality="100"
								src="../images/icons/star.svg"
								alt="star"
								placeholder="blurred"
								width={15}
								formats={["auto", "webp"]}
							/>
						</Col>
					</Row>
				</Row>
			</Card.Header>
			<Card.Body>
				<Row>
					<Col>
						<Card.Text>
							<p>
								<span className="text-primary">"</span>
								{review.reviewBody}
								<span className="text-primary">"</span>
							</p>
						</Card.Text>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

export default ReviewCard;
